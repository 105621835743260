define("ember-svg-jar/inlined/icon-retry", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "content": "<path fill=\"#FFF\" d=\"M7 0c2.147 0 4.132.963 5.444 2.525v-.97a.778.778 0 011.556 0v3.112c0 .43-.348.777-.778.777h-.347a.773.773 0 01-.08 0h-2.684a.778.778 0 110-1.555h1.422A5.553 5.553 0 007 1.556 5.445 5.445 0 001.597 6.32a.778.778 0 11-1.543-.192A7.001 7.001 0 017 0zM3.889 10.111h-1.42A5.543 5.543 0 007 12.444c2.75 0 5.06-2.05 5.401-4.754a.778.778 0 011.544.195A7.001 7.001 0 017 14a7.112 7.112 0 01-5.444-2.526v.97a.778.778 0 01-1.556 0v-3.11c0-.43.348-.778.778-.778h3.11a.778.778 0 110 1.555z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "width": "14",
      "height": "14",
      "viewBox": "0 0 14 14"
    }
  };
  _exports.default = _default;
});