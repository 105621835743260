define("web-core/modifiers/window-resize", ["exports", "@babel/runtime/helpers/esm/slicedToArray", "ember-modifier"], function (_exports, _slicedToArray2, _emberModifier) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = (0, _emberModifier.modifier)(function (_, _ref) {
    var _ref2 = (0, _slicedToArray2.default)(_ref, 1),
        handler = _ref2[0];

    window.addEventListener('resize', handler, {
      capture: false,
      passive: true
    });
    return function () {
      window.removeEventListener('resize', handler, {
        capture: false
      });
    };
  });

  _exports.default = _default;
});