define("web-core/utils/env-acquisition", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.fromWellKnown = fromWellKnown;

  function fromWellKnown(wellKnown) {
    var _wellKnown$environmen;

    return {
      environment: (_wellKnown$environmen = wellKnown.environment) !== null && _wellKnown$environmen !== void 0 ? _wellKnown$environmen : 'production',
      tenant: wellKnown.tenant,
      target: wellKnown.environment,
      apiHost: wellKnown['api-host'],
      oauthHost: wellKnown['oauth-host'],
      sso: wellKnown.sso,
      anonSignup: wellKnown['anon-signup'],
      webMainUrl: wellKnown['web-main-url'],
      webChatUrl: wellKnown['web-chat-url'],
      userManagementUrl: wellKnown['user-management-url']
    };
  }
});