define("web-core/types/narrow-event", ["exports", "web-core/models/event", "web-core/models/ephemeral-event"], function (_exports, _event, _ephemeralEvent) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.isSMessageText = isSMessageText;
  _exports.isSMessageArticle = isSMessageArticle;
  _exports.isSMessageEdit = isSMessageEdit;
  _exports.isSMessageMedia = isSMessageMedia;
  _exports.isSDelete = isSDelete;
  _exports.isSMessageAction = isSMessageAction;
  _exports.isSMessageActionInvocation = isSMessageActionInvocation;
  _exports.isSMessageActionValidation = isSMessageActionValidation;
  _exports.isSMessageTextTranslation = isSMessageTextTranslation;
  _exports.isSRoomPin = isSRoomPin;
  _exports.isSRoomBlocked = isSRoomBlocked;
  _exports.isSRoomStatus = isSRoomStatus;
  _exports.isSRoomMembership = isSRoomMembership;
  _exports.isSRoomCreate = isSRoomCreate;
  _exports.isSInvitation = isSInvitation;
  _exports.isSupportRequest = isSupportRequest;
  _exports.isSSupportRequestRejected = isSSupportRequestRejected;
  _exports.isSForm = isSForm;
  _exports.isSFormSubmission = isSFormSubmission;
  _exports.isSPresence = isSPresence;
  _exports.isSReceipt = isSReceipt;
  _exports.isSRoomTyping = isSRoomTyping;
  _exports.isSChange = isSChange;
  _exports.isSMessagePreview = isSMessagePreview;

  function isSMessageText(e) {
    return e.type === _event.EventTypes.sMessageText;
  }

  function isSMessageArticle(e) {
    return e.type === _event.EventTypes.sMessageArticle;
  }

  function isSMessageEdit(e) {
    return e.type === _event.EventTypes.sMessageEdit;
  }

  function isSMessageMedia(e) {
    return e.type === _event.EventTypes.sMessageMedia;
  }

  function isSDelete(e) {
    return e.type === _event.EventTypes.sDelete;
  }

  function isSMessageAction(e) {
    return e.type === _event.EventTypes.sMessageAction;
  }

  function isSMessageActionInvocation(e) {
    return e.type === _event.EventTypes.sMessageActionInvocation;
  }

  function isSMessageActionValidation(e) {
    return e.type === _event.EventTypes.sMessageActionInvocationValidation;
  }

  function isSMessageTextTranslation(e) {
    return e.type === _event.EventTypes.sMessageTextTranslation;
  }

  function isSRoomPin(e) {
    return e.type === _event.EventTypes.sRoomPin;
  }

  function isSRoomBlocked(e) {
    return e.type === _event.EventTypes.sRoomBlocked;
  }

  function isSRoomStatus(e) {
    return e.type === _event.EventTypes.sRoomStatus;
  }

  function isSRoomMembership(e) {
    return e.type === _event.EventTypes.sRoomMembership;
  }

  function isSRoomCreate(e) {
    return e.type === _event.EventTypes.sRoomCreate;
  }

  function isSInvitation(e) {
    return e.type === _event.EventTypes.sInvitation;
  }

  function isSupportRequest(e) {
    return e.type === _event.EventTypes.sSupportRequest;
  }

  function isSSupportRequestRejected(e) {
    return e.type === _event.EventTypes.sSupportRequestRejected;
  }

  function isSForm(e) {
    return e.type === _event.EventTypes.sForm;
  }

  function isSFormSubmission(e) {
    return e.type === _event.EventTypes.sFormSubmission;
  }

  function isSPresence(e) {
    return e.type === _ephemeralEvent.EventTypes.sPresence;
  }

  function isSReceipt(e) {
    return e.type === _ephemeralEvent.EventTypes.sReceipt;
  }

  function isSRoomTyping(e) {
    return e.type === _ephemeralEvent.EventTypes.sRoomTyping;
  }

  function isSChange(e) {
    return e.type === _ephemeralEvent.EventTypes.sChange;
  }

  function isSMessagePreview(e) {
    return e.type === _event.EventTypes.sMessagePreview;
  }
});