define("web-core/types/event-content/s.support.delegate", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.DelegateType = void 0;
  var DelegateType;
  _exports.DelegateType = DelegateType;

  (function (DelegateType) {
    DelegateType["Agent"] = "Agent";
    DelegateType["CRM"] = "CRM";
  })(DelegateType || (_exports.DelegateType = DelegateType = {}));
});