define("ember-svg-jar/inlined/icon-domain-disabled", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "content": "<path d=\"M885.5-148.85l-65.5-65.5v-398.02H422.22L410-623.35v-154.02H258.22l-65.74-65.26H475.5v165h410v528.78zM840.93-16.76l-94.87-95.1H74.5V-782.2l-52.72-52.7 43.68-44.92L884.85-60.67l-43.92 43.91zM140-177.37h105v-105H140v105zm0-165h105v-105H140v105zm0-165h105v-105H140v105zm165 330h105v-105H305v105zm0-165h105v-105H305v105zm165 165h210.04l-105-105H470v105zm245-270h-54.74l-5.26-5.26v-54.74h60v60z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 -960 960 960"
    }
  };
  _exports.default = _default;
});