define("web-core/utils/business-hours", ["exports", "@babel/runtime/helpers/esm/slicedToArray"], function (_exports, _slicedToArray2) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.within = within;
  _exports.isWithinBusinessHours = void 0;

  var isWithinBusinessHours = function isWithinBusinessHours(businessHours) {
    var timestamp = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : new Date().toISOString();
    if (!businessHours) return false;
    var timeZone = businessHours['time-zone']; // create the date representation in the specified timezone

    var dateInTimezone = new Date(new Date(timestamp).toLocaleString('en-US', {
      timeZone: timeZone
    }));
    var adjustedDay = dateInTimezone.getDay(); // check if blacklisted

    var blacklisted = businessHours.blacklist.some(function (item) {
      var a = createDateWithDate(item.from, timeZone);
      var b = createDateWithDate(item.until, timeZone);
      return within(dateInTimezone, a, b);
    });

    if (blacklisted) {
      return false;
    } // if not compare regular hours
    // find the hours configurations for today


    var todayBusinessHours = businessHours.hours.filter(function (hour) {
      return hour['week-day'] === adjustedDay;
    });
    if (todayBusinessHours.length === 0) return false;
    return todayBusinessHours.some(function (hour) {
      var from = createDateWithTime(hour.from, dateInTimezone);
      var until = createDateWithTime(hour.until, dateInTimezone);
      return within(dateInTimezone, from, until);
    });
  };

  _exports.isWithinBusinessHours = isWithinBusinessHours;

  function within(time, fromTime, untilTime) {
    return time >= fromTime && time <= untilTime;
  }

  function createDateWithTime(time, timezonedDate) {
    var _time$split$map = time.split(':').map(function (part) {
      return Number(part);
    }),
        _time$split$map2 = (0, _slicedToArray2.default)(_time$split$map, 2),
        hours = _time$split$map2[0],
        minutes = _time$split$map2[1];

    var adjustedDate = new Date(timezonedDate);
    adjustedDate.setHours(hours, minutes, 0);
    return adjustedDate;
  }

  var ISO_TIME_LENGTH = 'HH:MM:SS'.length;

  function createDateWithDate(isoString, timeZone) {
    // split iso string into date and time
    var _isoString$split = isoString.split('T'),
        _isoString$split2 = (0, _slicedToArray2.default)(_isoString$split, 2),
        dateString = _isoString$split2[0],
        time = _isoString$split2[1];

    var _dateString$split$map = dateString.split('-').map(function (part) {
      return Number(part);
    }),
        _dateString$split$map2 = (0, _slicedToArray2.default)(_dateString$split$map, 3),
        year = _dateString$split$map2[0],
        month = _dateString$split$map2[1],
        day = _dateString$split$map2[2];

    var _time$substring$split = time.substring(0, ISO_TIME_LENGTH).split(':').map(function (part) {
      return Number(part);
    }),
        _time$substring$split2 = (0, _slicedToArray2.default)(_time$substring$split, 3),
        hours = _time$substring$split2[0],
        minutes = _time$substring$split2[1],
        seconds = _time$substring$split2[2];

    var adjustedDate = new Date(new Date(dateString).toLocaleString('en-US', {
      timeZone: timeZone
    }));
    adjustedDate.setFullYear(year, month - 1, day);
    adjustedDate.setHours(hours, minutes, seconds !== null && seconds !== void 0 ? seconds : 0);
    return adjustedDate;
  }
});