define("ember-svg-jar/inlined/icon-event-busy", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "content": "<path d=\"M381.957-217.043l-44.914-44.914L436.087-360l-99.044-98.043 44.914-45.153L480-403.913l98.043-99.283 45.153 45.153L523.913-360l99.283 98.043-45.153 44.914L480-316.087l-98.043 99.044zM182.152-74.022q-27.599 0-47.865-20.265-20.265-20.266-20.265-47.865v-615.696q0-27.697 20.265-48.033 20.266-20.337 47.865-20.337H245v-60h69.065v60h331.87v-60H715v60h62.848q27.697 0 48.033 20.337 20.337 20.336 20.337 48.033v615.696q0 27.599-20.337 47.865-20.336 20.265-48.033 20.265H182.152zm0-68.13h595.696V-570H182.152v427.848z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "height": "48",
      "viewBox": "0 -960 960 960",
      "width": "48"
    }
  };
  _exports.default = _default;
});