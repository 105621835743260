define("web-core/utils/color", ["exports", "@babel/runtime/helpers/esm/toConsumableArray", "@babel/runtime/helpers/esm/slicedToArray"], function (_exports, _toConsumableArray2, _slicedToArray2) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.standardizeColor = standardizeColor;
  _exports.color = color;
  _exports.hexToRgb = hexToRgb;
  _exports.rgbToHsl = rgbToHsl;
  _exports.hslToRgb = hslToRgb;
  _exports.rgbToHex = rgbToHex;
  _exports.hslToHex = hslToHex;
  _exports.saturate = saturate;
  _exports.lighten = lighten;
  _exports.darken = darken;
  _exports.hue = hue;

  /* eslint-disable no-bitwise */
  var colorNode = document.createElement('div');

  function standardizeColor(name) {
    // Since invalid color's will be parsed as black, filter them out
    if ((name === null || name === void 0 ? void 0 : name.toLowerCase()) === 'black') {
      return '#000000';
    }

    var ctx = document.createElement('canvas').getContext('2d'); // @ts-ignore color accepts null which resets the prop to its initial configuration

    ctx.fillStyle = name;
    return ctx.fillStyle.toString();
  }

  function color(value, defaultValue) {
    // @ts-ignore color accepts null which resets the prop to its initial configuration
    colorNode.style.color = value;
    return typeof colorNode.style.color === 'string' && colorNode.style.color.length ? colorNode.style.color : defaultValue;
  }

  function hexToRgb(hex) {
    if (typeof hex !== 'string') return [0, 0, 0];

    if (hex[0] === '#') {
      hex = hex.substring(1);
    }

    if (hex.length === 3) {
      var _hex = hex,
          _hex2 = (0, _slicedToArray2.default)(_hex, 3),
          r = _hex2[0],
          g = _hex2[1],
          b = _hex2[2];

      hex = "".concat(r).concat(r).concat(g).concat(g).concat(b).concat(b);
    }

    var value = Number.parseInt(hex, 16);
    return [value >> 16 & 0x0000ff, value >> 8 & 0x0000ff, value & 0x0000ff];
  }

  function rgbToHsl(r, g, b) {
    r /= 255;
    g /= 255;
    b /= 255;
    var max = Math.max(r, g, b);
    var min = Math.min(r, g, b);
    var h = 0;
    var s = 0;
    var l = (max + min) / 2;

    if (max !== min) {
      var d = max - min;
      s = l > 0.5 ? d / (2 - max - min) : d / (max + min); // eslint-disable-next-line default-case

      switch (max) {
        case r:
          h = (g - b) / d + (g < b ? 6 : 0);
          break;

        case g:
          h = (b - r) / d + 2;
          break;

        case b:
          h = (r - g) / d + 4;
          break;
      }

      h /= 6;
    }

    return [h, s, l];
  }

  function hue2rgb(p, q, t) {
    if (t < 0) t += 1;
    if (t > 1) t -= 1;
    if (t < 1 / 6) return p + (q - p) * 6 * t;
    if (t < 1 / 2) return q;
    if (t < 2 / 3) return p + (q - p) * (2 / 3 - t) * 6;
    return p;
  }

  function hslToRgb(h, s, l) {
    var r = l;
    var g = l;
    var b = l;

    if (s !== 0) {
      // achromatic
      var q = l < 0.5 ? l * (1 + s) : l + s - l * s;
      var p = 2 * l - q;
      r = hue2rgb(p, q, h + 1 / 3);
      g = hue2rgb(p, q, h);
      b = hue2rgb(p, q, h - 1 / 3);
    }

    return [r * 255, g * 255, b * 255];
  }

  function rgbToHex(r, g, b) {
    var sum = r << 16 | g << 8 | b;
    return "#".concat(sum.toString(16).padStart(6, '0'));
  }

  function hslToHex(h, s, l) {
    return rgbToHex.apply(void 0, (0, _toConsumableArray2.default)(hslToRgb(h, s, l)));
  }

  function hexToHsl(color) {
    return rgbToHsl.apply(void 0, (0, _toConsumableArray2.default)(hexToRgb(color)));
  }

  var clamp = function clamp(val, min, max) {
    return Math.max(min, Math.min(max, val));
  };

  function saturate(color, amount) {
    var _hexToHsl = hexToHsl(color),
        _hexToHsl2 = (0, _slicedToArray2.default)(_hexToHsl, 3),
        h = _hexToHsl2[0],
        s = _hexToHsl2[1],
        l = _hexToHsl2[2];

    var adjusted = clamp(s + amount, 0, 1);
    return hslToHex(h, adjusted, l);
  }

  function lighten(color, amount) {
    var minLightness = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : 0;

    var _hexToHsl3 = hexToHsl(color),
        _hexToHsl4 = (0, _slicedToArray2.default)(_hexToHsl3, 3),
        h = _hexToHsl4[0],
        s = _hexToHsl4[1],
        l = _hexToHsl4[2];

    var adjusted = clamp(l + amount, minLightness, 1);
    return hslToHex(h, s, adjusted);
  }

  function darken(color, amount) {
    var maxLightness = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : 1;

    var _hexToHsl5 = hexToHsl(color),
        _hexToHsl6 = (0, _slicedToArray2.default)(_hexToHsl5, 3),
        h = _hexToHsl6[0],
        s = _hexToHsl6[1],
        l = _hexToHsl6[2];

    var adjusted = clamp(l - amount, 0, maxLightness);
    return hslToHex(h, s, adjusted);
  }

  function hue(color, amount) {
    var _hexToHsl7 = hexToHsl(color),
        _hexToHsl8 = (0, _slicedToArray2.default)(_hexToHsl7, 3),
        h = _hexToHsl8[0],
        s = _hexToHsl8[1],
        l = _hexToHsl8[2];

    var newHue = h + amount;
    var adjustedHue = newHue < 0 ? 1 + newHue : newHue;
    return hslToHex(adjustedHue % 1, s, l);
  }
});