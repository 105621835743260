define("web-core/types/event-content/s.room.status", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.RoomStatus = void 0;
  var RoomStatus;
  _exports.RoomStatus = RoomStatus;

  (function (RoomStatus) {
    RoomStatus["NONE"] = "NONE";
    RoomStatus["ROOM_REQUIRES_RESET"] = "ROOM_REQUIRES_RESET";
  })(RoomStatus || (_exports.RoomStatus = RoomStatus = {}));
});