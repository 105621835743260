define("web-core/types/event-content/s.room.membership", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.isMembershipInvite = isMembershipInvite;
  _exports.MembershipTrigger = void 0;
  var MembershipTrigger;
  _exports.MembershipTrigger = MembershipTrigger;

  (function (MembershipTrigger) {
    MembershipTrigger["Auto"] = "Auto";
    MembershipTrigger["Manual"] = "Manual";
  })(MembershipTrigger || (_exports.MembershipTrigger = MembershipTrigger = {}));

  function isMembershipInvite(content) {
    return content.membership === 'invite';
  }
});