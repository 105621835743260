define("ember-svg-jar/inlined/icon-article", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "content": "<path d=\"M277-279h275v-60H277v60zm0-171h406v-60H277v60zm0-171h406v-60H277v60zm-97 501q-24 0-42-18t-18-42v-600q0-24 18-42t42-18h600q24 0 42 18t18 42v600q0 24-18 42t-42 18H180zm0-60h600v-600H180v600zm0-600v600-600z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "height": "48",
      "viewBox": "0 -960 960 960",
      "width": "48"
    }
  };
  _exports.default = _default;
});