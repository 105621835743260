define("web-core/utils/event-merger", ["exports", "web-core/types/narrow-event"], function (_exports, _narrowEvent) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.shouldMergePrevious = shouldMergePrevious;
  _exports.shouldMergeNext = shouldMergeNext;
  _exports.MERGE_WINDOW = void 0;
  // merge if inside of 1min
  var MERGE_WINDOW = 1000 * 60;
  _exports.MERGE_WINDOW = MERGE_WINDOW;

  function shouldMergePrevious(event, previousEvent) {
    if (!previousEvent) return false;
    if (Ember.get(previousEvent, 'isNote') && !(0, _narrowEvent.isSMessageArticle)(event)) return false;
    var inMergeWindow = Ember.get(event, 'created').getTime() - Ember.get(previousEvent, 'created').getTime() <= MERGE_WINDOW;
    if (!inMergeWindow) return false; // from same sender

    return event.belongsTo('sender').id() === previousEvent.belongsTo('sender').id();
  }

  function shouldMergeNext(event, nextEvent) {
    if (!nextEvent) return false;
    if (Ember.get(nextEvent, 'isNote')) return false;
    var inMergeWindow = Ember.get(nextEvent, 'created').getTime() - Ember.get(event, 'created').getTime() <= MERGE_WINDOW;
    if (!inMergeWindow) return false; // from same sender

    return event.belongsTo('sender').id() === nextEvent.belongsTo('sender').id();
  }
});